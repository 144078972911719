import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Backdrop from "../../../Backdrop/Backdrop";
import EditLinkAudioCombiner from "./EditLinkAudioCombiner";
import Modal from "../../../Modal/Modal";
// import ModalConfirm from "../../../Modal/ModalConfirm";
import { useStore } from "../../../../hook-store/store";
import { getMetadataFromMetaList } from "../../../../utils/bucket/object-metadata-util";
// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../utils/file-move-util';
import * as bucketObjectUtils from "../../../../utils/bucket/bucket-object-util";

import { BACKEND_URL } from "../../../../App";

import classes from "./EditLinkModal.module.css";

function EditLinkModal(props) {
  const {
    // setClickedActionInfoHandler,
    object,
    // isLoading,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // const { 
  //   allObjectsMetaList,
  // } = store.objectMetadataStore;
  const { 
    editLinkModalData,
    imageUrlList,
   } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);

  const closeEditLinkModalHandler = () => {
    dispatch('SET_SHOWEDITLINKMODAL', false);
    dispatch('SET_EDITLINKMODALDATA', null);
  };

  let imageUrlObj;
  let smallImageUrl;

  // if (editLinkModalData?.key) {
  //   imageUrlObj = imageUrlList.find(element => {
  //     return element.key === editLinkModalData.key;
  //   });

  //   smallImageUrl = imageUrlObj?.smallImageUrl;

  //   if (!smallImageUrl) {
  //     smallImageUrl = imageUrlObj?.imageUrl;
  //   }
  // }


  let editLinkModalContent;

  if (editLinkModalData?.openApp === 'audioCombiner') {
    editLinkModalContent = (
      <EditLinkAudioCombiner
        // smallImageKey={smallImageKey} 
        smallImageUrl={smallImageUrl}
        // parsedMetadata={parsedMetadata}
      />
    );
  }

  // if (editLinkModalData?.openApp === 'imageToImageGeneration') {
  //   editLinkModalContent = (
  //     <EditLinkImageToImage
  //       // smallImageKey={smallImageKey} 
  //       smallImageUrl={smallImageUrl}
  //       // parsedMetadata={parsedMetadata}
  //     />
  //   );
  // }

  // const modalStyle = {
  //   zIndex: 200,
  // };

  let editLinkModalBody;

  editLinkModalBody = (
    <div>
      <Backdrop
        zIndex={"110"}
        // backgroundColor={'rgba(0, 0, 0, 0.1)'}
        onCancel={() => {
          if (!isLoading) {
            closeEditLinkModalHandler();
            // setClickedActionInfoHandler(null);
          }
        }}
      />
      <Modal
        onClose={() => { 
          if (!isLoading) {
            closeEditLinkModalHandler();
            // setClickedActionInfoHandler(null);
          } 
        }}
        // onAccept={() => { }}
        hideButtons={true}
        modalStyle={{ zIndex: 200, }}
        isLoading={isLoading}
      >
        <div className={classes.editLinkModal}>
          <div>
            {/* edit-link-modal-content
            key: {editLinkModalData.key}
            openApp: {editLinkModalData.openApp}
            mimeType: {editLinkModalData.mimeType} */}
            <div>
              {editLinkModalContent}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );

  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {editLinkModalBody}
    </Fragment>
  );
}

export default EditLinkModal;
