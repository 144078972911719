// const React = window.React = require('react');
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AboutTextAudioTools from './AboutTextAudioTools';

import { useStore } from '../../hook-store/store';

import { loginPageLink, signupPageLink } from '../../App';

import { marks } from '../../utils/marks';
import classes from './AboutPage.module.css';

import adImage1 from '../../images/kura-storage-ad-image-1.png';
import adImage2 from '../../images/kura-storage-ad-image-2.png';



const AboutPage = props =>  {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  let aboutPageBody;

  aboutPageBody = (
    <div className={classes.aboutPage}>
      <div className={classes.aboutPageTitle}>
        About Kura Storage
      </div>
      <p>
        Kura Storage is
        {' '}
        {t('aboutPage.01', 'simple storage place for your files. You can upload, store, access, and download files.')}
      </p>
      <p>
        {t('aboutPage.02', 'There is free storage space to upload files. It is possibe to access files from both mobile devices and desktop devices.')}
      </p>
      <p>
        {t('aboutPage.03', 'When you need more space to store files, you can upgrade to larger file storage space anytime.')}
      </p>

      <div className={classes.aboutPageImageContainer}>
        <img className={classes.aboutPageImage}
          src={adImage1}
        />
        <img className={classes.aboutPageImage}
          src={adImage2}
        />
      </div>

      <br/>
      <AboutTextAudioTools />
      <br/>
      {/* <br/> */}

      <p>
        <a
            href="https://kura-storage-tool.spaceeight.net"
            target="_blank" rel="noopener noreferrer"
          >
            Kura Storage Tools
        </a>
        {' '}
        {t('aboutPage.10', 'has simple text editor to create documents, and spreadsheet for data manipulation and calculation.')}
        <br/>  
        <br/>
        {t('aboutPage.11', 'It is possible to import Word file or Excel file for editing.')}
        {' '}
        {t('aboutPage.12', 'After creation or editing of files, it is possibe to store files at')} 
        {' '}
        Kura Storage.
        {' '}
        {t('aboutPage.13', 'Files can be downloaded as Word file, PDF file, or Excel file.')} 
        <br/>
        <br/>
        <a
            href="https://kura-storage-tool.spaceeight.net"
            target="_blank" rel="noopener noreferrer"
          >
            Kura Storage Tools
        </a>
        {' '}
        {t('aboutPage.14', 'has a simple tool to create slide presentaion as well. Created slide presentation files can be stored at')}
        {' '}Kura Storage. 
      </p>
      <br/>
      <br/>

      <div>
        <strong>
          {t('aboutPage.16', 'Collaboration with other users')}
        </strong>
      </div>
      <p>
        {t('aboutPage.15', 'It is possible to do video talk and meeting with other users ')}
        {' '}
        <a href={`https://remeet.watakura.xyz/new-room-from-above-link`}
          target="_blank" rel="noopener noreferrer"
        >
          by using watakura REMEET
        </a>
      </p>
      <p>
        {t('aboutPage.17', 'File sharing with other users allows to collaborate on various files.')}
        {' '}
        {t('aboutPage.18', 'Synchronized file editing enables smooth collaboration between remote users.')}
      </p>
      <br/>
      <p>
       {t('aboutPage.04', 'For storing image files or photos, there is a storage service')}
        {' '}
        <a 
          href="https://kura-image-photo.spaceeight.net"
          target="_blank" rel="noopener noreferrer"
        >
          Kura Image Photo.
        </a>
        {' '}
        {t('aboutPage.19', 'It has various image editing and generation tools.')}
      </p>
      

      {!isAuth && (
        <div>
          <p>
            {t('aboutPage.06', 'Login to upload and store files, or create account')}
          </p>

          <div className={classes.aboutPageButtons}>
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.07', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.08', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.09', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      )}

    </div>
  );

  return (
    <Fragment>
      {aboutPageBody}
    </Fragment>
  );
}
export default AboutPage;

