import { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import _, { result } from 'lodash';
// import axios from 'axios';

// import Backdrop from '../../Backdrop/Backdrop';
// import BucketObjectAllList from './BucketObjectAllList';
import BucketObjectList from './BucketObjectList';
import EditLinkModal from './EditLinkModal/EditLinkModal';
// import Loader from '../../Loader/Loader';
import ObjectControl from '../ObjectControl/ObjectControl';
import Trashbox from '../Trashbox/Trashbox';
import TrashMenu from '../Trashbox/TrashMenu/TrashMenu';
import ShareObjectList from '../ShareBuckets/ShareObjectList/ShareObjectList';

import { useStore } from '../../../hook-store/store';

import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import * as fileMoveUtils from '../../../utils/bucket/file-move-util';
import * as objectAccessUtils from '../../../utils/bucket/object-access-util';

import { BACKEND_URL } from '../../../App';




import ObjectAccess from '../ObjectAccess/ObjectAccess';
import { marks } from '../../../utils/marks';

import ObjectSearchHandle from './ObjectSearchHandle';


function BucketObjects(props) {

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefix = store.bucketObjectStore.movePrefix;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const allBucketObjects = store.bucketObjectStore.allBucketObjects;
  const searchSelectedItem = store.bucketObjectStore.searchSelectedItem;
  const objectAction = store.bucketObjectStore.objectAction;

  const displayTable = store.bucketObjectStore.displayTable;
  
  const gLoading = store.uiStore.gLoading;

  const {
    showEditLinkModal,
  } = store.bucketObjectStore;
  
  const [bucketObjects, setBucketObjects] = useState([]);
  const [objectSignedUrl, setObjectSignedUrl] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  // useEffect(() => {
  //   dispatch('SET_BUCKETNAME', 'test-access-bucket');
  // },[]);
  
  useEffect(() => {
    // getBucketObjects(bucketName);
    // getBucketCommonPrefixes(bucketName);
    if (bucketName && !currentPrefix) {
      // getAllBucketObjects(bucketName, '');
      // getBucketCommonPrefixes(bucketName);
    }
  },[bucketName, currentPrefix]);

  useEffect(() => {
    // if (!currentPrefix && allBucketObjects.length > 0) {
    //   createToplevelsHandler('', allBucketObjects);
    // }

    if (allBucketObjects.length > 0) {
      createToplevelsHandler(currentPrefix, allBucketObjects);
    }
  },[currentPrefix, allBucketObjects]);


  //// clear selected files, folders, destination folder when currentPrefix changed
  useEffect(() => {
    dispatch('SET_MOVEPREFIXES', []);
    dispatch('SET_SELECTED_FILEKEYS', []);
    dispatch('SET_SELECTED_PREFIX', '');
    dispatch('SET_SHOWOBJECTCONTROL', false);
    dispatch('SET_OBJECTS_ACTIONTYPE', '');
    dispatch('SET_SHOWFOLDERSMODAL', false);
  },[currentPrefix, topLevels]);


  useEffect(() => {
    if (bucketObjects.length > 0) {
      
      let topLevels = [];
      if (!currentPrefix) {
        // topLevels = bucketObjects.filter(object => {
        //   return object.Key.split('/').length <= 2;
        // });
        topLevels = bucketObjects;
      } else {
        topLevels = bucketObjects.filter(object => {
          const splitKey = object.Key.split(`/`);
          return (!splitKey[splitKey.length - 1] && splitKey.length <= currentPrefix.split('/').length + 1 ) ||
            splitKey.length <= currentPrefix.split('/').length;
        });
      }
      
      console.log('currentPrefix', currentPrefix);
      console.log('topLevels', topLevels);

      let topLevelComponents = [];
      for (const object of topLevels) {
        if (!currentPrefix) {
          // if (object.Key.split('/').length >= 2) {
          //   topLevelComponents.push({
          //     prefix: object.Key.split('/')[0] + '/'
          //   });
          // } else {
          //   topLevelComponents.push(object);
          // }
          topLevelComponents = topLevels;
        } else {
            topLevelComponents.push(object);
        }
      }
      console.log(topLevelComponents);
      // setTopLevels(topLevelComponents);

      
      // dispatch('SET_TOPLEVELS', topLevelComponents);
      
      let searchTopLevels;

      if (searchSelectedItem) {
        const searchItemPrefix = bucketObjectUtils.getPrefixFromKey(searchSelectedItem.Key);

        const isInTopLevels = topLevelComponents.find(element => {
          return element.Key === searchSelectedItem.Key;
        });

        if (searchItemPrefix === currentPrefix && isInTopLevels) {

          searchTopLevels = bucketObjectUtils.createSearchedTopLevels(
            searchSelectedItem, 
            topLevelComponents,
          );
        } 
      } 

      if (searchTopLevels) {
        dispatch('SET_TOPLEVELS', searchTopLevels);
      }
      else {
        dispatch('SET_TOPLEVELS', topLevelComponents);
      }

    }
  },[bucketObjects]);







  useEffect(() => {
    console.log('objectAction', objectAction);
    if (objectAction && (objectAction.add || objectAction.delete) ) {
      
      if (objectAction.actionType === 'tools-file-update') {
        
        //// tools-file-update case (update in tools app), update currentPrefix data
        getAllObjects(bucketName, '');

        // if (!currentPrefix) {
        //   getBucketCommonPrefixes(bucketName);
        // } else {
        //   getAllBucketObjects(bucketName, currentPrefix);
        // }

      } else {
        
        //// update object in this app case (normal)
        getAllObjects(bucketName, '');
      }
    }
  },[objectAction]);

  useEffect(() => {
    if (bucketName && allBucketObjects.length === 0) {
      getAllObjects(bucketName, '');
    }
  },[bucketName]);


  useEffect(() => {
    if (allBucketObjects.length > 0) {
      createObjectsList(allBucketObjects);
    }
  },[allBucketObjects]);



  // useState(() => {
  //     console.log(currentPrefix);
  //     const currentLevels = bucketObjects.filter(object => {
  //       return  object.Key.startsWith(object.Key) && object.Key.split('/').length === currentPrefix.split('/').length;
  //     });

  //     console.log('currentLevels', currentLevels);

  // },[currentPrefix]);

  useEffect(() => {
    getObjectAccessHandler();
  },[]);


  useEffect(() => {
    // window.history.replaceState({}, null, encodeURIComponent(`?table=${displayTable}`) || '/');
    if (displayTable) {
      window.history.replaceState({}, null, `?table=${displayTable}`);
    } else {
      window.history.replaceState({}, null, `/`);
    }
  },[displayTable]);





  // useEffect(() => {
  //  const pxTl = createPrefixTopLevelsFromAllList(currentPrefix, allBucketObjects);
  //   console.log(pxTl);
  // },[currentPrefix]);




  // useEffect(() => {
  //   if (searchSelectedItem) {
  //     const prefixFromKey = bucketObjectUtils.getPrefixFromKey(searchSelectedItem.Key);
  //     console.log(prefixFromKey, searchSelectedItem);

  //     if (!prefixFromKey && !currentPrefix) {

  //       const searchTopLevels = bucketObjectUtils.createSearchedTopLevels(searchSelectedItem, topLevels);
        
  //       if (searchTopLevels) {
  //         dispatch('SET_TOPLEVELS', searchTopLevels);
  //         // dispatch('SET_SEARCH_SELECTEDITEM', null);
  //       }
  //     }

  //     if (!prefixFromKey && currentPrefix) {
  //       getBucketCommonPrefixes(bucketName);
  //     }

  //     if (prefixFromKey && !currentPrefix) {
  //       getAllBucketObjects(bucketName, prefixFromKey);
  //     }

  //     if (prefixFromKey && currentPrefix) {
  //       if (prefixFromKey === currentPrefix) {
  //         const searchTopLevels = bucketObjectUtils.createSearchedTopLevels(searchSelectedItem, topLevels);
        
  //         if (searchTopLevels) {
  //           dispatch('SET_TOPLEVELS', searchTopLevels);
  //           // dispatch('SET_SEARCH_SELECTEDITEM', null);
  //         }
  //       } else {
  //         getAllBucketObjects(bucketName, prefixFromKey);
  //       }
  //     }

  //   }
  // },[searchSelectedItem]);




  const setCurrentPrefixHandler = (prefix) => {
    // console.log(prefix); 
    // setCurrentPrefix(prefix);
    dispatch('SET_CURRENT_PREFIX', prefix);
  };


  const getBucketObjects = async (bucket, prefix) => {
    try {
      const result = await fetch(BACKEND_URL + `/bucket/objects?bucket=${bucket}&prefix=${prefix}`);

      if (result.ok) {
        const resData = await getBucketCommonPrefixes(bucketName);
        setBucketObjects(resData.data.Contents);
      }
    } catch(err) {
      console.log(err);
    }
  };

  const getAllBucketObjects = async (bucket, prefix) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getAllBucketObjects(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        prefix,
      );
      // const result = await fetch(BACKEND_URL + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`);
      console.log(resData);

      dispatch('SET_CURRENT_PREFIX', prefix);

      setBucketObjects(resData.data.objectList);
      setCurrentPrefixHandler(resData.data.params.Prefix);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  const getBucketCommonPrefixes = async (bucket) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getBucketCommonPrefixes(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
      );

      console.log(resData);

      // const prefixes = resData.data.bucketObjects.CommonPrefixes;
      // console.log(resData.data.bucketObjects.CommonPrefixes)
      // const combined = prefixes.concat(resData.data.bucketObjects.Contents);
      const prefixes = resData.data.bucketObjects.allCommonPrefixes;
      const modifiedPrefixes = prefixes.map(px => {
        return {
          Prefix: px.Prefix,
          Key: px.Prefix,
        }
      });
      // console.log(resData.data.bucketObjects.allCommonPrefixes)

      // const combined = prefixes.concat(resData.data.bucketObjects.allKeys);
      const combined = resData.data.bucketObjects.allKeys.concat(modifiedPrefixes);
      console.log(combined);

      dispatch('SET_CURRENT_PREFIX', '');
      setBucketObjects(combined);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };



  const createToplevelsHandler = async (prefix, allBucketObjects) => {

    setIsLoading(true);
    dispatch('SET_GLOADING', true);

    const prefixTopLevels = bucketObjectUtils.createPrefixTopLevelsFromAllList(prefix, allBucketObjects);

    dispatch('SET_CURRENT_PREFIX', prefix);

    // setBucketObjects(resData.data.objectList);
    setBucketObjects(prefixTopLevels);
    // setCurrentPrefixHandler(resData.data.params.Prefix);

    setIsLoading(false);
    dispatch('SET_GLOADING', false);

  };



  const deleteBucketObjectsHandler = async (bucket, keys) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);


      let resultsForAction = {
        objectDelete: null,
      };


      if (!bucket || !keys || keys.length === 0) {
        throw new Error('bucket and keys are required');
      };

      console.log(keys, bucket);
      const resData = await bucketObjectUtils.deleteBucketObjects(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        keys,
      );

      console.log(resData);
      
      resultsForAction.objectDelete = resData;


      const deletedKeys = [];
      
      for (const key of keys) {
        deletedKeys.push({
          Key: key,
        });
      }

      const deletedList = fileMoveUtils.createDeletedObjectList(keys, topLevels);
      dispatch('SET_TOPLEVELS', deletedList);
      dispatch('SET_SELECTED_FILEKEYS',[]);


      dispatch('SET_OBJECTACTION', {
        actionType: 'delete-object',
        delete: true,
        add: false,
        result: resultsForAction,
      });


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'deletion success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);


      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `deletion failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  const getFolderContents = async (bucket, prefix) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getBucketFolderContents(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        prefix,
      );

      console.log(resData);

      // const prefixes = resData.data.bucketObjects.CommonPrefixes;
      // console.log(resData.data.bucketObjects.CommonPrefixes)

      // const combined = prefixes.concat(resData.data.bucketObjects.Contents);
      // console.log(combined);

      // dispatch('SET_CURRENT_PREFIX', '');
      // setBucketObjects(combined);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  const getObjectSignedUrlHandler = async (bucket, key, expires) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getObjectSignedUrl(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        key,
        expires,
      );

      console.log(resData);
      
      const dataUrl = resData.data.url;
      // console.log(decodeURI(dataUrl));
      // console.log(key, resData.data.url.indexOf(key), resData.data.url.indexOf('?'));
      // console.log(decodeURI(dataUrl).substring(resData.data.url.indexOf(key), resData.data.url.indexOf('?')))
      
      
      // const fileExt = firstPart.split('.')[firstPart.split('.').length -1].toLowerCase();
      // const fileExt = key.split('.').pop();
      // console.log(fileExt);

      const myImage = document.getElementById('imgtag');
      // myImage.src = URL.createObjectURL(imageUrl);
      myImage.src = dataUrl;

      setObjectSignedUrl(dataUrl);
      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };

  // const fileDownloadHandler = async (bucket, key, expires) => {
  //   const resData = await bucketObjectUtils.getObjectSignedUrl(
  //     BACKEND_URL,
  //     localStorage.getItem('token'),
  //     bucket,
  //     key,
  //     expires,
  //   );

  //   console.log(resData);
    
  //   const dataUrl = resData.data.url;
    
  //   bucketObjectUtils.openInNewTab(dataUrl);
  // };


  const deleteFolderHandler = async (bucket, prefix) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);


      let resultsForAction = {
        folderDelete: null,
      };


      const result = await bucketObjectUtils.deleteFolders(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        [prefix],
      );

      console.log(result);

      resultsForAction.folderDelete = result;


      dispatch('SET_MOVEPREFIX', '');
      dispatch('SET_SELECTED_PREFIX', '');

      dispatch('SET_MOVEPREFIXES', []);

      const deletedList = fileMoveUtils.createFoldersDeletedObjectList([prefix], topLevels, currentPrefix);
      dispatch('SET_TOPLEVELS', deletedList);


      dispatch('SET_OBJECTACTION', {
        actionType: 'delete-folder',
        delete: true,
        add: false,
        result: resultsForAction,
      });

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'deletion success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `deletion failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);


      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };

  // const deleteFoldersHandler = async (bucket, prefixes) => {
  //   try {
  //     setIsLoading(true);

  //     const result = await bucketObjectUtils.deleteFolders(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       bucket,
  //       prefixes,
  //     );

  //     console.log(result);

  //     dispatch('SET_MOVEPREFIX', '');
  //     dispatch('SET_SELECTED_PREFIX', '');

  //     dispatch('SET_MOVEPREFIXES', []);

  //     const deletedList = fileMoveUtils.createFoldersDeletedObjectList(prefixes, topLevels, currentPrefix);
  //     dispatch('SET_TOPLEVELS', deletedList);


  //     setIsLoading(false);
  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };

  const deleteFilesFoldersHandler = async (bucket, prefixes, keys) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      console.log('delete file folder num', keys.length, prefixes.length);

      let deletedList;
      // console.log(keys, prefixes);
      if (keys.length > 0) {
        // await deleteBucketObjectsHandler(bucket, keys);

        if (!bucket || !keys || keys.length === 0) {
          throw new Error('bucket and keys are required');
        };
  
        console.log(keys, bucket);
        const resData = await bucketObjectUtils.deleteBucketObjects(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          keys,
        );
  
        console.log(resData);
        
        const deletedKeys = [];
        
        for (const key of keys) {
          deletedKeys.push({
            Key: key,
          });
        }
  
        deletedList = fileMoveUtils.createDeletedObjectList(keys, topLevels);
        dispatch('SET_TOPLEVELS', deletedList);
        dispatch('SET_SELECTED_FILEKEYS',[]);

        // setIsLoading(false);
        // dispatch('SET_GLOADING', false);
      }

      if (prefixes.length > 0) {
        // await deleteFoldersHandler(bucket, prefixes);

        // setIsLoading(true);
        // dispatch('SET_GLOADING', true);

        const result = await bucketObjectUtils.deleteFolders(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          prefixes,
        );
  
        console.log(result);
  
        dispatch('SET_MOVEPREFIX', '');
        dispatch('SET_SELECTED_PREFIX', '');
  
        dispatch('SET_MOVEPREFIXES', []);
  
        const deletedList2 = fileMoveUtils.createFoldersDeletedObjectList(prefixes, deletedList, currentPrefix);
        dispatch('SET_TOPLEVELS', deletedList2);
  
  
        // setIsLoading(false);
        // dispatch('SET_GLOADING', false);
      }


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'deletion success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      


      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `deletion failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  const storeObjectAccessHandler = async (key) => {
    try {
      const result = await objectAccessUtils.storeObjectAccess(
        BACKEND_URL,
        localStorage.getItem('token'), 
        key
      );

      if (result.data) {
        const addedList = [result.data].concat(store.bucketObjectStore.accessList);
        dispatch('SET_ACCESSLIST', addedList);
      }
    } catch(err) {
      console.log(err);
    }
  };

  const getObjectAccessHandler = async (key) => {
    try {
      const result = await objectAccessUtils.getObjectAccess(
        BACKEND_URL,
        localStorage.getItem('token')
      );
      
      console.log(result);

      if (result.data.accessList) {
        dispatch('SET_ACCESSLIST', result.data.accessList);
      }

    } catch(err) {
      console.log(err);
    }
  };




  const getAllObjects = async (bucket, prefix) => {
    try {
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getAllBucketObjects(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        prefix,
      );
      // const result = await fetch(BACKEND_URL + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`);
      console.log(resData);

      // dispatch('SET_CURRENT_PREFIX', prefix);
      
      dispatch('SET_ALLBUCKETOBJECTS', resData.data.objectList);

      dispatch('SET_SERVICEBUCKETSDATALIST', resData.data.serviceDataList);
      // setBucketObjects(resData.data.objectList);

      // setCurrentPrefixHandler(resData.data.params.Prefix);

      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  const createObjectsList = (allObjects) => {
    const prefixList = [];
    const fileList = [];
    let totalSize = 0;

    for (const object of allObjects) {
      if (object.Key.endsWith('/')) {
        // prefixList.push({
        //   ...object,
        //   Prefix: object.Key 
        // });
        prefixList.push(object);
        // totalSize = totalSize + object.Size;
      } 
      else {
        fileList.push(object);
        totalSize = totalSize + (object.Size / 10**9);
      }
    }
    // console.log('totalSize', totalSize);

    dispatch('SET_ALLFOLDERLIST', prefixList);
    dispatch('SET_ALLFILELIST', fileList);
    dispatch('SET_BUCKETSIZEGB', totalSize);

  }



  
  return (
    <Fragment>

      {/* <div>---------</div> */}
      {/* {isLoading && <div>loading...loading...</div>} */}

      
      {/* {isLoading && (
        <div>
          <Backdrop 
            zIndex={'500'}
            onCancel={() => { 
              if (!isLoading) {
                // setClickedObjectKeyHandler(''); 
              }
          }}
          />
          <span 
            style={{fontSize:"3rem", color:"gray", position:"fixed", top:"50%", right:"50%", zIndex:'500'}}>
              
              <Loader />
          </span>
        </div>
      )} */}


      {/* <div>bucket-objects-content</div>
      <div>
        get-image-50:  <img style={{height:"50px"}} id='imgtag' alt='getimage' />
      </div>
     
      <div>bucket-name: {bucketName}</div>
      <div>currentPrefix: {currentPrefix}</div>
      {currentPrefix && (
        <button 
        disabled={isLoading}
          onClick={() => {
            if (!isLoading) {
              getFolderContents(bucketName, currentPrefix);
            }
          }}
        >
          get-folder-top-level-contents
        </button>
      )}

      <div>selected-files-keys</div>
      <div>
        {selectedFileKeys.map(key => {
          return <span>{key}, </span>
        })}
      </div>
      <div>
        move-prefixes: {movePrefixes.map(mPx => {
          return (<span>{mPx}, </span>)
        })}
      </div>
      <hr/> */}

{/* <button onClick={() => {
        if (!displayTable) {
          dispatch('SET_DISPLAYTABLE', 'recent');
        } else {
          dispatch('SET_DISPLAYTABLE', '');
        }
      }}>{marks.replyMark} switch-tables</button>
<Link to="/test">to-test-page</Link>
{` `}
<Link to="/">go-back-page</Link> */}

      <section>
        {displayTable !== 'trashbox' && (
          <ObjectControl />
        )}
        {displayTable === 'trashbox' && (
          <TrashMenu />
        )}
        {/* <Link to="/test-route" >test-route</Link> */}
      </section>

      {!displayTable && (
        <BucketObjectList 
          getAllBucketObjects={getAllBucketObjects}
          deleteBucketObjectsHandler={deleteBucketObjectsHandler}
          getBucketCommonPrefixes={getBucketCommonPrefixes}
          getFolderContents={getFolderContents}
          getObjectSignedUrlHandler={getObjectSignedUrlHandler}
          // fileDownloadHandler={fileDownloadHandler}
          deleteFolderHandler={deleteFolderHandler}
          deleteFilesFoldersHandler={deleteFilesFoldersHandler}
          objectSignedUrl={objectSignedUrl}
          storeObjectAccessHandler={storeObjectAccessHandler}
          createToplevelsHandler={createToplevelsHandler}
          isLoading={isLoading}
        />
      )}


      {displayTable === 'recent' && (
        <ObjectAccess
          getAllBucketObjects={getAllBucketObjects}
          storeObjectAccessHandler={storeObjectAccessHandler}
          createToplevelsHandler={createToplevelsHandler}
        />
      )}

      {displayTable === 'trashbox' && (
        <Trashbox
          getAllBucketObjects={getAllBucketObjects}
          storeObjectAccessHandler={storeObjectAccessHandler}
          createToplevelsHandler={createToplevelsHandler}
        />
      )}

      {displayTable === 'share-files' && (
        <ShareObjectList />
      )}

      <ObjectSearchHandle 
        getBucketCommonPrefixes={getBucketCommonPrefixes}
        getAllBucketObjects={getAllBucketObjects}
        createToplevelsHandler={createToplevelsHandler}
      />

      {showEditLinkModal && (
        <EditLinkModal />
      )}
    </Fragment>
  );
}

export default BucketObjects;
