import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

// import FolderAndFile from "../../FolderAndFile/FolderAndFile";

import { useStore } from "../../../../hook-store/store";
import { 
  isAudioMimeType,
  getOriginalFileNameFromKey,
 } from "../../../../utils/bucket/bucket-object-util";

// import { textAudioToolsLink } from "../../../../App";

import { marks } from "../../../../utils/marks";
import classes from "./EditLinkModal.module.css";

function EditLinkAudioCombinerAddFile(props) {
  const {
    ipaImageKeys,
    setIpaImageKeys,
    paramData,
    setParamData,
    targetUrl,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    editLinkModalData,
    imageUrlList,
   } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);


  const deleteIpaImaegeKeyHandler = (id) => {
    const deletedKeys = ipaImageKeys.filter(element => {
      return id !== element.id;
    });

    setIpaImageKeys(deletedKeys);

    // const deletedIpaImages = paramData.files.filter(ele => {
    //   return id !== ele.id;
    // });

    // console.log(deletedKeys);

    setParamData({
      files: [
                { key: editLinkModalData.key, mimeType: editLinkModalData.mimeType }
              ].concat(deletedKeys)
    });
  };

  // const targetUrl = `${textAudioToolsLink}/audio-editor?keyData=${JSON.stringify(paramData)}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=audioCombiner`

  let editLinkImageStylizerIpaImagesBody;

  if (ipaImageKeys.length === 0) {
    editLinkImageStylizerIpaImagesBody = (
      <div>no file selected</div>
    )
  }

  if (ipaImageKeys?.length > 0) {
    editLinkImageStylizerIpaImagesBody = (
      <div>
        <ul className={classes.editLinkIpaImageList}>
          {ipaImageKeys.map(element => {
          // console.log(element)
          // const imageUrlObj = element.imageUrlObj;
          // const smallImageUrl = imageUrlObj?.smallImageUrl;
          
          // let dispImageUrl = smallImageUrl;

          // if (!smallImageUrl) {
          //   dispImageUrl = imageUrlObj?.imageUrl
          // }

          return (
            <li key={element.key}>
              <div>
                <div>
                  <strong>
                    {getOriginalFileNameFromKey(element.key)}
                  </strong>
                  {/* <img className={classes.editLinkIpaImage}
                    // style={{maxHeight:"50px"}}
                    src={dispImageUrl}
                  /> */}
                  {' '}
                  <button
                    style={{padding: "0.25rem"}}
                    onClick={() => { deleteIpaImaegeKeyHandler(element.id) }}
                  >
                    {marks.trashMark} delete
                  </button>
                </div>
                <div>
                </div>
              </div>
            </li>
            );
          })}
        </ul>

        <div className={classes.editLinkSection}>
          <a 
            href={targetUrl}
            target="_blank" rel="noreferrer noopener"
            // style={}
          >
            <button className="btnBase">
              open files in Audio Combiner
            </button>
          </a>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {editLinkImageStylizerIpaImagesBody}
    </Fragment>
  );
}

export default EditLinkAudioCombinerAddFile;
