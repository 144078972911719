// const React = window.React = require('react');
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../hook-store/store';

import { textAudioToolsLink } from '../../App';

import { marks } from '../../utils/marks';
import classes from './AboutPage.module.css';



const AboutTextAudioTools = props =>  {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  const textAuditoToolLinkEl = (
    <a
      href={textAudioToolsLink}
      target="_blank" rel="noopener noreferrer"
    >
      Kura Text Audio Tools
    </a>
  )
  let aboutTextAudioToolsBody;

  aboutTextAudioToolsBody = (
    <div>
      <div>
        <strong>
          Kura Text Audio Tools
        </strong>
      </div>
      <p>
        {textAuditoToolLinkEl}
        {' '}
        {t('aboutTextAudio01', 'provide tools for handling text and audio data.')}
        {' '}
        {t('aboutTextAudio02', 'That includes')} 
        {' '}
        Audio Transcriber, Voice Generator, Answer Finder.
      </p>
      <p>
        In Audio Transcriber, 
        {' '}
        {t('aboutTextAudio03', 'by using advanced AI-technologies, user can generate text transcript from various audio sources.')}
        {' '}
        {t('aboutTextAudio04', 'It is possible to choose a language for generating text from more than 90 different languages.')}
        <br/>
        <br/>
        In Voice Generator, 
        {' '}
        {t('aboutTextAudio05', 'user can generate audio from text input.')}
        {' '}
        {t('aboutTextAudio06', 'User can control output audio speed and select voices from more than 900 different voices.')}
        {' '}
        {/* {t('aboutTextAudio09', 'Supported language is English.')} */}
        <br/>
        <br/>
        In Answer Finder, 
        {' '}
        {t('aboutTextAudio07', 'User can find an answer in context text by asking question.')}
        {' '}
        In Answer Finder, {t('aboutTextAudio09', 'Supported language is English.')}
        {' '}
      </p>
      <p>
        {t('aboutTextAudio08', 'Generated audio and text data can be stored')} 
        {' '}
        at Kura Storage.
      </p>
    </div>
  );

  return (
    <Fragment>
      {aboutTextAudioToolsBody}
    </Fragment>
  );
}
export default AboutTextAudioTools;

