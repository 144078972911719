import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../../Loader/Loader';
import { useStore } from '../../../../hook-store/store';
import { 
  getObjectFile, 
} from '../../../../utils/bucket/bucket-object-util';
// import { createTokenForTools } from '../../../../utils/bucket/object-for-tools';
// import { 
//   getMetadataFromMetaList,
//   getParsedMetadataFromObjectMeta,
//  } from '../../../../utils/bucket/object-metadata-util';
// import { mimeTypes } from '../../../../utils/file/mime-type';

import { BACKEND_URL } from '../../../../App';

// import { marks } from '../../../../utils/marks';

import classes from './TextPreview.module.css'
// import * as bucketObjectUtils from '../utils/bucket-object-util';

const initialLimit = 300;

function TextPreview(props) {

  const {
    // setClickedActionInfoHandler,
    // setClickedObjectKeyHandler,
    object,
    // tokenForToolsHandler,
    // isLoading,
  } = props;

  // console.log('ToolsLink.js-props', props, object.Key);
    const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const { bucketName } = store.bucketObjectStore;
  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  // const [showForImageModal, setShowForImageModal] = useState(false);
  // const [audioSignedUrl, setAudioSignedUrl] = useState('');
  const [fileText, setFileText] = useState('');
  const [showFullText, setShowFullText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // // console.log('object', object);

  // let isPresentationFile = false;

  // //// user's file case
  // const objectMetadata = getMetadataFromMetaList(object.Key, allObjectsMetaList);

  // if (objectMetadata && objectMetadata.parsedMetadata.isPresentationFile) {
  //   isPresentationFile = true;
  // }

  // //// share file case
  // if (object.objectMeta) {
  //   const parsedMeta = getParsedMetadataFromObjectMeta(object.objectMeta);

  //   if (parsedMeta && parsedMeta.isPresentationFile && 
  //       parsedMeta.shareInfo && parsedMeta.shareInfo.ownerId !== localStorage.getItem('userId')
  //   ) {
  //     isPresentationFile = true;
  //   }
  //   // console.log('object parsedMeta', parsedMeta);
  // }

  useEffect(() => {
    getTextFileHandler();
  },[]);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const getTextFileHandler = async () => {
    try {
      // dispatch('SET_GLOADING', true);
      setIsLoading(true);

      const resData = await getObjectFile(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucketName,
        object.Key,
        object.mimeType,
      );

      console.log(resData);

      if (resData) {
        const blobUrl = URL.createObjectURL(resData);
        const result = await fetch(blobUrl);
        const resText = await result.text();
        setFileText(resText);
        // setAudioSignedUrl(resData.data.url);
      }

      // dispatch('SET_GLOADING', false);
      setIsLoading(false);

    } catch(err) {
      console.log(err);
      // dispatch('SET_GLOADING', false);
      setIsLoading(false);
      throw err;
    }
  };



  let textPreviewBody

  if (fileText?.length <= initialLimit) {
    textPreviewBody = (
      <div>
        <div>
          {fileText}
        </div>
      </div>
    );
  }

  if (fileText?.length > initialLimit && !showFullText) {
    textPreviewBody = (
      <div>
        <div>
          {fileText.slice(0, initialLimit)}....
        </div>
        {/* <div>
          <button 
            onClick={() => { setShowFullText(true) }}
          >
            show-full-text
          </button>
        </div> */}
      </div>
    );
  }

  if (fileText && showFullText) {
    textPreviewBody = (
      <div>
        <div>
          {fileText}
        </div>
        {/* <div>
          <button 
            onClick={() => { setShowFullText(false) }}
          >
            show-full-text
          </button>
        </div> */}
      </div>
    );
  }


 

  return (
    <Fragment>
      {isLoading && (
        <Loader />
      )}
      <div className={classes.textPreview}>
        {textPreviewBody}
      </div>
    </Fragment>
  );
}

export default TextPreview;
