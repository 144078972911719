import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_CURRENT_PREFIX: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          currentPrefix: payload,
        }
      };
    },
    SET_BUCKETNAME: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          bucketName: payload,
        }
      };
    },
    SET_PREFIXLIST: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          prefixList: payload,
        }
      };
    },
    SET_CURRENTPREFIX_CONTENTS: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          currentPrefixContents: payload,
        }
      };
    },
    SET_SELECTED_FILEKEYS: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          selectedFileKeys: payload,
        }
      };
    },
    SET_SELECTED_PREFIX: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          selectedPrefix: payload,
        }
      };
    },
    SET_MOVEPREFIX: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          movePrefix: payload,
        }
      };
    },
    SET_MOVEPREFIXES: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          movePrefixes: payload,
        }
      };
    },
    SET_TOPLEVELS: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          topLevels: payload,
        }
      };
    },
    SET_SHOWOBJECTCONTROL: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          showObjectControl: payload,
        }
      };
    },
    SET_OBJECTS_ACTIONTYPE: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          objectsActionType: payload,
        }
      };
    },
    SET_SHOWFOLDERSMODAL: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          showFoldersModal: payload,
        }
      };
    },
    SET_ACCESSLIST: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          accessList: payload,
        }
      };
    },
    SET_DISPLAYTABLE: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          displayTable: payload,
        }
      };
    },
    SET_ALLBUCKETOBJECTS: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          allBucketObjects: payload,
        }
      };
    },
    SET_ALLFOLDERLIST: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          allFolderList: payload,
        }
      };
    },
    SET_ALLFILELIST: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          allFileList: payload,
        }
      };
    },
    SET_BUCKETSIZEGB: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          bucketSizeGB: payload,
        }
      };
    },
    SET_TOTALSIZEGB: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          totalSizeGB: payload,
        }
      };
    },
    SET_LIMITGB: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          limitGB: payload,
        }
      };
    },

    SET_SEARCH_SELECTEDITEM: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          searchSelectedItem: payload,
        }
      };
    },

    SET_OBJECTACTION: (state, payload) => {
      const action = {
        actionType: payload.actionType,
        delete: payload.delete, // true, false 
        add: payload.add,
        result: payload.result,
        time: Date.now(),
      };

      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          objectAction: action,
        }
      };
    },
    SET_ISUPLOADFAILED: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          isUploadFailed: payload,
        }
      };
    },
    SET_SHOWMEETINGINFO: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          showMeetingInfo: payload,
        }
      };
    },
    
    SET_RESERVEDPREFIXES: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          reservedPrefixes: payload,
        }
      };
    },
    SET_SHOWEDITLINKMODAL: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          showEditLinkModal: payload,
        }
      };
    },
    SET_EDITLINKMODALDATA: (state, payload) => {
      return {
        // ...state,
        bucketObjectStore: {
          ...state.bucketObjectStore,
          editLinkModalData: payload,
        }
      };
    },

  };

  initStore(actions, {
    bucketObjectStore: {
      currentPrefix: '',
      bucketName: '',
      prefixList: [],
      currentPrefixContents: null,
      selectedFileKeys: [],
      selectedPrefix: '',
      movePrefix: '',
      movePrefixes: [],
      topLevels: [],
      showObjectControl: false,
      objectsActionType: '',
      showFoldersModal: false,
      accessList: [],
      displayTable: '',
      allBucketObjects: [],
      allFolderList: [],
      allFileList: [],
      bucketSizeGB: 0,
      totalSizeGB: 0,
      limitGB: 0.1,
      freeSizeGB: 0.1,

      searchSelectedItem: null,
      objectAction: null,
      isUploadFailed : false,
      showMeetingInfo: false,

      reservedPrefixes: [],
      showEditLinkModal: false,
      editLinkModalData: null,
    }
  });
};

export default configureStore;
