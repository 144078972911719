import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';


import { useStore } from '../../../../hook-store/store';
import { 
  getObjectSignedUrl, 
  isAudioMimeType, 
  isVideoMimeType,
} from '../../../../utils/bucket/bucket-object-util';
// import { createTokenForTools } from '../../../../utils/bucket/object-for-tools';
// import { 
//   getMetadataFromMetaList,
//   getParsedMetadataFromObjectMeta,
//  } from '../../../../utils/bucket/object-metadata-util';
// import { mimeTypes } from '../../../../utils/file/mime-type';

import { BACKEND_URL, kuraToolsLink } from '../../../../App';

// import { marks } from '../../../../utils/marks';

import classes from './FileActions.module.css'
// import * as bucketObjectUtils from '../utils/bucket-object-util';


function AudioPreview(props) {

  const {
    // setClickedActionInfoHandler,
    // setClickedObjectKeyHandler,
    object,
    // tokenForToolsHandler,
    // isLoading,
  } = props;

  // console.log('ToolsLink.js-props', props, object.Key);
    const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const { bucketName } = store.bucketObjectStore;
  // const { 
  //   allObjectsMetaList,
  // } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  // const [showForImageModal, setShowForImageModal] = useState(false);
  const [audioSignedUrl, setAudioSignedUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  // // console.log('object', object);

  // let isPresentationFile = false;

  // //// user's file case
  // const objectMetadata = getMetadataFromMetaList(object.Key, allObjectsMetaList);

  // if (objectMetadata && objectMetadata.parsedMetadata.isPresentationFile) {
  //   isPresentationFile = true;
  // }

  // //// share file case
  // if (object.objectMeta) {
  //   const parsedMeta = getParsedMetadataFromObjectMeta(object.objectMeta);

  //   if (parsedMeta && parsedMeta.isPresentationFile && 
  //       parsedMeta.shareInfo && parsedMeta.shareInfo.ownerId !== localStorage.getItem('userId')
  //   ) {
  //     isPresentationFile = true;
  //   }
  //   // console.log('object parsedMeta', parsedMeta);
  // }

  useEffect(() => {
    getSingedUrlHandler();
  },[]);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const getSingedUrlHandler = async () => {
    try {
      dispatch('SET_GLOADING', true);
      const resData = await getObjectSignedUrl(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucketName,
        object.Key,
      );

      console.log(resData);

      if (resData.data.url) {
        setAudioSignedUrl(resData.data.url);
      }

      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
      throw err;
    }
  };



  // const tokenForToolsHandler = async (url, token, bucket, key) => {
  //   try {

  //     const tokenForTools = await createTokenForTools(
  //       url,
  //       token,
  //       bucket,
  //       key,
  //     );

  //     // console.log(tokenForTools);

  //     const tokenForCasTGT = tokenForTools.tokenForCasTGT;
  //     const TGTexp = tokenForTools.TGTexp;
      

  //     if (tokenForCasTGT && TGTexp) {
  //       window.open(`${kuraToolsLink}/fileload?tokenForCasTGT=${tokenForCasTGT}&TGTExp=${TGTexp}`, '_blank');
  //       // window.open(`${kuraToolsLink}/fileload?tokenForCasTGT=${tokenForCasTGT}&TGTExp=${TGTexp}`,"_self");
  //     }

  //   } catch(err) {
  //     console.log(err);
  //   }
  // }
  
  // let fileBucket = bucketName;

  // if (object.bucket && object.bucket !== bucketName) {
  //   fileBucket = object.bucket;
  // }

  let audioPreviewBody

  if (audioSignedUrl) {
    if (isAudioMimeType(object.mimeType)) {
      audioPreviewBody = (
        <div>
          {/* {audioSignedUrl} */}
          <audio className={classes.objectActionAudio}
            src={audioSignedUrl}
            controls
          />
        </div>
      )
    }

    if (isVideoMimeType(object.mimeType)) {
      audioPreviewBody = (
        <div>
          {/* {audioSignedUrl} */}
          <video className={classes.objectActionAudio}
            src={audioSignedUrl}
            controls
          />
        </div>
      )
    }
  }
 

  return (
    <Fragment>
      {audioPreviewBody}
    </Fragment>
  );
}

export default AudioPreview;
