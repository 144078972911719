import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../hook-store/store';

import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
import * as fileMoveUtils from '../../../../utils/bucket/file-move-util';
import * as uploadUtils from '../../../../utils/bucket/bucket-upload-util';
// import { 
//   confirmModalStyle, 
//   confirmModalMobileStyle,
//   confirmModalWideStyle,
//  } from '../Modal/modalStyle';

import { BACKEND_URL } from '../../../../App';

import classes from './FolderNameChange.module.css';

function FolderNameChange(props) {

  const {
    setClickedActionInfoHandler,
    object,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  // const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  // const movePrefix = store.bucketObjectStore.movePrefix;
  // const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const { allBucketObjects } = store.bucketObjectStore;

  const gLoading = store.uiStore.gLoading;

  const [newNamePrefix, setNewNamePrefix] = useState('');
  const [inputError, setInputError] = useState('');
  const [trimedInput, setTrimedInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    const folderName = bucketObjectUtils.getFolderNameFromKey(object.Key);
    setTrimedInput(folderName.trim());
  },[]);


  const folderNameChangeInputHandler = (event, key) => {
    setInputError('');
    // console.log(event.target.value, key);
    // let newName = event.target.value.trim().replace(/\s/g, '');
    const newName = event.target.value;

    if (newName.includes('/')) {
      setInputError('name should not have "/"');
    }

    if (newName.length > 100) {
      setInputError('maximum name length is 100');
    }

    if (setTrimedInput && !newName) {
      setInputError('name is required');
    }

    setTrimedInput(newName);
    
    const originalName = key.split('/')[key.split('/').length -2];
    // console.log(originalName)

    const withoutFileName = key.slice(0, (key.length - (originalName.length + 1)) );
    // console.log(withoutFileName);

    // console.log(withoutFileName + newName + '/');
    setNewNamePrefix(withoutFileName + newName + '/');

  };




  const changeFolderNameHandler = async (bucket, newName, oldPrefix, newPrefix) => {
    try {

      dispatch('SET_GLOADING', true);
      // console.log(oldPrefix);

      let resultsForAction = {
        addedTopLevels: null,
        moveFilesResult: null,
        moveFoldersResult: null,
        deleteFolderResult: null,
      };

      //// for metadata update
      let addKeys = [];

      const newFolderKey = currentPrefix + newName + '/';
      addKeys = addKeys.concat(newFolderKey);

      //// create new folder
      const addedTopLeves = await createFolderHandler(bucket, newName);

      resultsForAction.addedTopLevels = addedTopLeves;

      //// get objects of old prefix
      // const oldFolderObjects = await getAllObjects(bucket, oldPrefix)
      const oldFolderObjects = bucketObjectUtils.getFolderObjectsFromAllList(oldPrefix, allBucketObjects);
      console.log(oldFolderObjects);
      // console.log(oldFolderObjects);

      const mPrefixes = [];
      const mKeys = [];

      for (const object of oldFolderObjects) {
        if (object.Key !== oldPrefix) {
          if (object.Key.split('/').length === oldPrefix.split('/').length) {
            mKeys.push(object.Key);
          }

          if (
            object.Key.split('/').length === oldPrefix.split('/').length + 1 && 
            object.Key.endsWith('/')
          ) {
            mPrefixes.push(object.Key);
          }
        }
      }

      console.log(mPrefixes, mKeys);

      //// move and delete get objects to new folder
      if (mKeys.length > 0) {
        const moveFilesResult = await moveDeleteFiles(bucket, mKeys, newPrefix);
        
        resultsForAction.moveFilesResult = moveFilesResult;

        addKeys = addKeys.concat(moveFilesResult.dataForMetadata.addKeys);
      }

      if (mPrefixes.length > 0) {
        const moveFoldersResult = await moveDeleteFolders(bucket, mPrefixes, newPrefix);
      
        resultsForAction.moveFoldersResult = moveFoldersResult;

        addKeys = addKeys.concat(moveFoldersResult.dataForMetadata.addKeys);
      }

      // console.log(moveDeleteResult);


      //// delete old folder
      const deleteFolderResult = await deleteFolders(bucket, [oldPrefix], addedTopLeves);

      resultsForAction.deleteFolderResult = deleteFolderResult;


      dispatch('SET_OBJECTACTION', {
        actionType: 'change-folder-name',
        delete: true,
        add: true,
        result: resultsForAction,
      });

      console.log('addKeys', addKeys);

      const dataForMetadata = {
        addKeys: addKeys,
      };

      dispatch('SET_DATAFORMETADATA', dataForMetadata);


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'folder name changed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `folder name change failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      dispatch('SET_GLOADING', false);
    }

  };

  
  const createFolderHandler = async (bucket, folderName) => {
    try {
      // setIsLoading(true);

      if(!folderName || folderName.includes('/')) {
        throw new Error('folder name is not provided or contains / ');
      }

      const result = await bucketObjectUtils.createFolder(
        BACKEND_URL,
        localStorage.getItem('token'),
        currentPrefix + folderName + '/',
        bucket,
      );

      console.log(result);

      const folderKey = currentPrefix + folderName + '/';
      const addedList = uploadUtils.createFolderAddedObjectList(folderKey, topLevels, currentPrefix);
      
      // dispatch('SET_TOPLEVELS', addedList);

      // setIsLoading(false);
      return addedList;
      
    } catch(err) {
      console.log(err);
      // setIsLoading(false);
    }
  };


  // const getAllObjects = async (bucket, prefix) => {
  //   try {
  //     // setIsLoading(true);

  //     const resData = await bucketObjectUtils.getAllBucketObjects(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       bucket,
  //       prefix,
  //     );
  //     // const result = await fetch(BACKEND_URL + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`);
  //     console.log(resData);

      
  //     // setIsLoading(false);
  //     return resData.data.objectList;
  //   } catch(err) {
  //     console.log(err);
  //     // setIsLoading(false);
  //   }
  // };

  const moveDeleteFiles = async (bucket, inputKeys, targetPrefix) => {
    try {
      // setIsLoading(true);

      const result = await bucketObjectUtils.moveDeleteBucketObjects(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        inputKeys,
        targetPrefix,
      );

      console.log(result);

      // setIsLoading(false);
      return result;
    } catch(err) {
      console.log(err);
      // setIsLoading(false);
    }
  };


  const moveDeleteFolders = async (bucket, inputKeys, targetKey) => {
    try {
      // setIsLoading(true);

      const result = await bucketObjectUtils.moveDeleteFolders(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        inputKeys,
        targetKey,
      );

      console.log(result);

      // setIsLoading(false);

      return result;
    } catch(err) {
      console.log(err);
      // setIsLoading(false);
    }
  };


  const deleteFolders = async (bucket, prefixes, topLevels) => {
    try {
      // setIsLoading(true);

      const result = await bucketObjectUtils.deleteFolders(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        prefixes,
      );

      console.log(result);

      // dispatch('SET_MOVEPREFIX', '');
      // dispatch('SET_SELECTED_PREFIX', '');

      // dispatch('SET_MOVEPREFIXES', []);

      const deletedList = fileMoveUtils.createFoldersDeletedObjectList(prefixes, topLevels, currentPrefix);
      dispatch('SET_TOPLEVELS', deletedList);


      // setIsLoading(false);
      return deletedList;

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
    }
  };



  let folderNameChangeBody;


  if (object.Key) {
    folderNameChangeBody = (
      <span>
        <input className={`inputBase ${classes.folderNameInput}`}
          type="text"
          value={trimedInput}
          onChange={(event) => { 
            if (!isLoading) {
              folderNameChangeInputHandler(event, object.Key); 
            }
          }}
          autoFocus
        />
        <div>{inputError}</div>
        <div className={classes.folderNameChangeButtons}>
          <button className="btnBase"
            disabled={isLoading}
            // name="change-file-name"
            onClick={(event) => {
              if (!isLoading) {
                setClickedActionInfoHandler(null);
              }
            }}
          >
            {t('folderAction.03', 'Cancel')}
          </button>
          <button className="btnBase"
            disabled={isLoading || inputError || !trimedInput}
            name="select-object"
            onClick={(event) => {
              if (!isLoading) {
                changeFolderNameHandler(
                  bucketName, 
                  trimedInput, 
                  object.Key, 
                  newNamePrefix,
                  );
                // filenameChangeHandler(bucketName, object.Key, newNameKey);
                // setClickedActionInfoHandler(event, object.Key);
              }
            }}
          >
            {t('folderAction.04', 'Change Name')}
          </button>
        </div>
 
      </span>
    );
  }



  return (
    <Fragment>
      {folderNameChangeBody}
    </Fragment>
  );
}

export default FolderNameChange;
