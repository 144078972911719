import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import moment from 'moment';
// import { saveAs } from 'file-saver';

import AudioPreview from './AudioPreview';
import Backdrop from '../../../Backdrop/Backdrop';
import FileAddTrashboxMeta from './FileAddTrashboxMeta';
import FileDownload from './FileDownload';
import FileShareModalContent from './FileShareModal/FileShareModalContent';
import ModalConfirm from '../../../Modal/ModalConfirm';
import TextAudioToolsLink from './TextAudioLink';
import TextPreview from './TextPreview';
import ToolsLink from './ToolsLink';

import { useStore } from '../../../../hook-store/store';
import { 
  isAudioMimeType, 
  isVideoMimeType,
} from '../../../../utils/bucket/bucket-object-util';
import { 
  getObjectMetadata,
  addTrashMetadata,
  getParsedMetadataFromObjectMeta,
 } from '../../../../utils/bucket/object-metadata-util';
import { 
  updateShareMetadata 
} from '../../../../utils/share-bucket/share-bucket-util';
import { mimeTypes } from '../../../../utils/file/mime-type';
import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from './FileActions.module.css';


function FileActions(props) {

  const {
    setClickedActionInfoHandler,
    getObjectSignedUrlHandler,
    // fileDownloadHandler,
    deleteBucketObjectsHandler,
    selectedFileKeysHandler,
    setClickedObjectKeyHandler,
    clickedActionInfo,
    objectSignedUrl,
    isInSelectedFileKeys,
    object,
    tokenForToolsHandler,
    // isLoading,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    currentPrefix,
    topLevels,
  } = store.bucketObjectStore;
  
  const { allObjectsMetaList } = store.objectMetadataStore;
  
  const { gLoading } = store.uiStore;

  const [showEditUseFile, setShowEditUseFile] = useState(true);
  const [showAudioPreview, setShowAudioPreview] = useState(false);
  const [showObjectDetail, setShowObjectDetail] = useState(false);
  const [showTextPreview, setShowTextPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const addTrashboxMetaHandler = async (bucketName, currentPrefix, allObjectsMetaList) => {
    try {

      dispatch('SET_GLOADING', true);

      let oldMetadata;
      
      const objectMeta = allObjectsMetaList.find(element => {
        return element.Key === object.Key;
      });

      if (objectMeta) {
        oldMetadata = objectMeta
      }
    

      // const objectMeta = await getObjectMetadata(
      //   BACKEND_URL,
      //   localStorage.getItem('token'),
      //   bucketName,
      //   object.Key,
      // );
      
      // if (objectMeta) {
      //   oldMetadata = objectMeta.data;
      // }
  

    console.log(objectMeta, oldMetadata);

    if (oldMetadata) {
      console.log('oldMetadata', oldMetadata);

      let updateMeta;

      if (oldMetadata.objectMeta.Metadata.metastring) {
        const parsedOldMeta = JSON.parse(oldMetadata.objectMeta.Metadata.metastring);
        updateMeta = {
          ...parsedOldMeta,
          location: 'trashbox',
          trashMoveTime: Date.now(),
        };
      } else {
        updateMeta = {
          location: 'trashbox',
          trashMoveTime: Date.now(),
        };
      }

      // const updateMeta = {
      //   ...parsedOldMeta,
      //   location: 'trashbox',
      //   trashMoveTime: Date.now(),
      // };

      console.log(updateMeta);

      // return;

      const resData = await addTrashMetadata(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          currentPrefix,
          object.Key,
          updateMeta,
          dispatch,
        );
      
        console.log(resData);


        if (resData.data) {

          dispatch('SET_OBJECTACTION', {
            actionType: 'update-metadata',
            delete: true,
            add: true,
            result: resData.data,
          });

          const noOldObjectMetaList = allObjectsMetaList.filter(element => {
            return element.Key !== object.Key;
          });
          
          const addedList = noOldObjectMetaList.concat({
            Key: resData.data.Key,
            objectMeta: resData.data.objectMeta,
          });

          dispatch('SET_ALLOBJECTSMETALIST', addedList);
   
          dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'file moved to trash box',
            message: '',
          });
    
          setTimeout(() => {
            dispatch('CLEAR_NOTIFICATION');
          },1000 * 3);
        }

        dispatch('SET_GLOADING', false);

    }
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `file move to trashbox failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };

  // let deleteModalBody;

  // if (clickedActionInfo && clickedActionInfo.name === 'delete-object') {
  //   deleteModalBody = (
  //     <ModalConfirm 
  //     onClose={() => { setClickedActionInfoHandler(null); }}
  //     onAccept={() => { deleteBucketObjectsHandler(bucketName, [object.Key]); }}
  //     isLoading={isLoading}
  //   >
  //     <div>
  //     {t('fileAction.01', 'delete file')} ?
  //     </div>
  //   </ModalConfirm>
  //   )
  // }

  let addTrashMetaModalBody;

  if (clickedActionInfo && clickedActionInfo.name === 'file-add-trashbox-meta') {
    addTrashMetaModalBody = (
      <div>
        <Backdrop 
          zIndex={'110'}
          // backgroundColor={'rgba(0, 0, 0, 0.1)'}
          onCancel={() => { 
            if (!isLoading) {
              setClickedActionInfoHandler(null);
            }
          }}
        />  
        <ModalConfirm 
          onClose={() => { setClickedActionInfoHandler(null); }}
          onAccept={() => { 
            addTrashboxMetaHandler(
              bucketName,
              currentPrefix,
              allObjectsMetaList,
            );
           }}
          isLoading={isLoading}
        >

          <div>
            {t('trash.13', 'move to trash box')} ?
          </div>
        </ModalConfirm>
      </div>
    )
  }


  let fileShareModalBody;

  if (clickedActionInfo && clickedActionInfo.name === 'file-share') {
    fileShareModalBody = (
      <div>
      <Backdrop 
        zIndex={'110'}
        // backgroundColor={'rgba(0, 0, 0, 0.1)'}
        onCancel={() => { 
          if (!isLoading) {
            setClickedActionInfoHandler(null);
          }
        }}
      />
      <ModalConfirm
        onClose={() => { setClickedActionInfoHandler(null); }}
        hideButtons={true}
      >
        <FileShareModalContent
          object={object}
          setClickedActionInfoHandler={setClickedActionInfoHandler}
        />
      </ModalConfirm>
      </div>
    )
  }


  const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  const timeEndIndex = fileName.indexOf('Z');
  // console.log(timeEndIndex);
  const originalName = fileName.substring(timeEndIndex+2);


  let parsedMeta;

  const objectMeta = allObjectsMetaList.find(element => {
    return element.Key === object.Key;
  });

  if (objectMeta?.objectMeta?.Metadata?.metastring) {
    parsedMeta = JSON.parse(objectMeta.objectMeta.Metadata.metastring);
  }


  let fileActionsBody;

  fileActionsBody = (
    <div className={classes.objectActionModal}
    // style={{display:"flex", flexDirection:"column"}}
    >
      
      {/* {deleteModalBody} */}

      {/* <button name="get-url"
        disabled={isLoading}
        onClick={(event) => { 
          if (!isLoading) {
            getObjectSignedUrlHandler(bucketName, object.Key, 3600);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        get-signed-url
      </button>
      {objectSignedUrl && clickedActionInfo.key === object.Key && (
        <div>
          url-for-download (valid for 1 hour): <a href={objectSignedUrl} target='_blank' rel="noreferrer noopener">
            {objectSignedUrl}
            </a>
        </div>
      )} */}

    <button className={classes.objectActionListItem}
      name="filename"
      onClick={(event) => { 
        if (!isLoading) {
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {originalName}
      {' '}
      ({numeral(object.Size).format('0.0 b')})
      {' '}

      {/* <span onClick={() => { setShowObjectDetail(true) }}>
        {marks.triangleDown}
      </span>
      {showObjectDetail && parsedMeta && (
        <div>
          {parsedMeta.createdAt && (
            <div>
              created-at: {moment(parsedMeta.createdAt).format("YYYY-MM-DD h:mm a")}, 
            </div>
          )}
          {object.LastModified && (
            <div>
              last-modified: {moment(object.LastModified).format("YYYY-MM-DD h:mm a")},
            </div>
          )}
          {parsedMeta.uploadInfo?.uploadApp && (
            <div>
              upload-app: {parsedMeta.uploadInfo?.uploadApp}
            </div>
          )}
        </div>
      )} */}
    </button>

    {(isAudioMimeType(object.mimeType) || isVideoMimeType(object.mimeType)) && (
      <button className={classes.objectActionListItem}
        name="audio-preview"
        onClick={(event) => { 
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
            setShowAudioPreview(true);
          }
        }}
      >
        {marks.playCircleMark}{' '} 
        {t('fileAction.24', 'play media file')}
      </button>
    )}

    {object.mimeType === mimeTypes.txt && (
      <button className={classes.objectActionListItem}
        name="text-preview"
        onClick={(event) => { 
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
            setShowTextPreview(true);
          }
        }}
      >
        {marks.fileTextOMark}{' '}
        {t('fileAction.25', 'preview text file')}
      </button>
    )}

    {/* <button className={classes.objectActionListItem}
      name="download"
      disabled={isLoading}
      onClick={(event) => { 
        if (!isLoading) {
          fileDownloadHandler(bucketName, object.Key, 3600); 
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {marks.downloadMark} download file
    </button> */}
    <FileDownload 
      setClickedActionInfoHandler={setClickedActionInfoHandler}
      object={object}
    />

    {/* <button className={classes.objectActionListItem}
      name="delete-object"
      disabled={isLoading}
      onClick={(event) => {
        if (!isLoading) {
          // deleteBucketObjectsHandler(bucketName, [object.Key]);
          setClickedActionInfoHandler(event, object.Key);
          // setShowDeleteModal(true);
        }
      }}
    >
      {marks.trashMark} {t('fileAction.01', 'delete file')}
    </button> */}

    {/* {!isInSelectedFileKeys && (
      <button name="select-object" 
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            selectedFileKeysHandler(object.Key, 'add', selectedFileKeys);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        select-add-object
      </button>
    )}
    {isInSelectedFileKeys && (
      <button name="deselect-object"
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            selectedFileKeysHandler(object.Key, 'delete', selectedFileKeys);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        select-delete-object
      </button>
    )} */}

  
    <button className={classes.objectActionListItem}
      disabled={isLoading}
      name="change-file-name"
      onClick={(event) => {
        if (!isLoading) {
          setClickedActionInfoHandler(event, object.Key);
          setClickedObjectKeyHandler('');
        }
      }}
    >
      {marks.editMark} {t('fileAction.02', 'change file name')}
    </button>

    {/* <button className={classes.objectActionListItem}
      disabled={isLoading}
      name="edit-use-open"
      onClick={(event) => {
        if (!isLoading) {
          setShowEditUseFile(!showEditUseFile);
        }
      }}
    >
      Open file {marks.triangleDown}
    </button> */}
    
    <TextAudioToolsLink 
      setClickedActionInfoHandler={setClickedActionInfoHandler}
      setClickedObjectKeyHandler={setClickedObjectKeyHandler}
      object={object}
    />

    <ToolsLink 
      setClickedActionInfoHandler={setClickedActionInfoHandler}
      setClickedObjectKeyHandler={setClickedObjectKeyHandler}
      object={object}
    />


    <button className={classes.objectActionListItem}
      disabled={isLoading}
      name="file-share"
      onClick={(event) => {
        if (!isLoading) {
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {marks.usersMark} {t('shareList.05', 'manage share file')}
    </button>

    <FileAddTrashboxMeta 
      object={object}
      setClickedActionInfoHandler={setClickedActionInfoHandler}
    />



    {/* <button
      onClick={() => {
        bucketObjectUtils.getObjectFile(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          object.Key,
          object.mimeType,
        ).then(resData => {
          return resData.text();
          // console.log(resData);
        }).then(text => {
          console.log(text);

          if (text.startsWith('<')) {
            // return HTMLtoDOCX(text);
            // const converted = htmlDocx.asBlob(text);
            // saveAs(converted, 'test-convert.docx');
          }
        }).then(converted => {
          console.log(converted);
        })
      }}
    >
      get-object-file
    </button> */}
  </div>
  )


  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {/* {deleteModalBody} */}
      {addTrashMetaModalBody}
      {fileShareModalBody}
      {fileActionsBody}

      {showAudioPreview && (
        <div>
          <Backdrop 
            zIndex={'110'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            onCancel={() => { 
              if (!isLoading) {
                setShowAudioPreview(false);
              }
            }}
          />
        <ModalConfirm
          hideButtons={true}
          onClose={() => { setShowAudioPreview(false);}}
        >
          <AudioPreview 
            object={object}
          />
        </ModalConfirm>
        </div>
      )}

      {showTextPreview && (
        <div>
          <Backdrop 
            zIndex={'110'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            onCancel={() => { 
              if (!isLoading) {
                setShowTextPreview(false);
              }
            }}
          />
        <ModalConfirm
          hideButtons={true}
          onClose={() => { setShowTextPreview(false);}}
        >
          <TextPreview 
            object={object}
          />
        </ModalConfirm>
        </div>
      )}
    </Fragment>
  );
}

export default FileActions;
